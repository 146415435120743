import {useAuthenticatedState} from '@imperium/auth-client';
import debug from 'debug';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useGetUserByIdQuery} from '../components/UserProfile/getUserById';
import {setOfficeId, setOrganizationId, setOrganizationName, setUserId, setUserName} from '../state';

const d = debug('tacs.web.user.hooks.useUserInfo');

export function useUserInfo() {
	const dispatch = useDispatch();
	const {id} = useAuthenticatedState();

	d(`Rendering useUserInfo hook: ${id}`);

	// todo: is this query here the best approach?
	useGetUserByIdQuery({
		skip: !id,
		variables: {
			userId: id || '',
		},
		onCompleted: ({getUserById}) => {
			dispatch(setUserId(id || null));
			dispatch(setUserName(getUserById?.name || null));
			dispatch(setOrganizationId(getUserById?.activeAgent?.organization?.id || null));
			dispatch(setOrganizationName(getUserById?.activeAgent?.organization?.name || null));
			dispatch(setOfficeId(getUserById?.activeAgent?.office?.id || null));
		},
	});

	useEffect(() => {
		if (!id) {
			dispatch(setUserId(null));
			dispatch(setUserName(null));
			dispatch(setOrganizationId(null));
			dispatch(setOfficeId(null));
		}
	}, [dispatch, id]);

	return null;
}
