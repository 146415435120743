import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'semantic-ui-react';
import {OfficeDropdown} from '~common/components/OfficeDropdown';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useEditAgentOfficeMutation} from './editAgentOffice';
import {useGetEditAgentOfficeQuery} from './getEditAgentOffice';

const d = debug('tacs.web.user.components.EditAgentOffice');

export function EditAgentOffice() {
	const throwError = useAsyncError();
	const {userId} = useParams<typeof routes.types.userEdit>();
	const [editMutation, {loading: mutationLoading}] = useEditAgentOfficeMutation();
	const {data, loading, error, refetch} = useGetEditAgentOfficeQuery({variables: {userId: userId || ''}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {officeId: data?.getUserById?.activeAgent?.office?.id},
		onSubmit: formValues => {
			if (data?.getUserById?.activeAgent) {
				editMutation({
					variables: {data: {id: data?.getUserById?.activeAgent.id, officeId: formValues.officeId, version: data.getUserById?.activeAgent.version}},
				})
					.then(async () => {
						toast.success('Office Saved');
						setEditClicked(false);
						await refetch();
					})
					.catch(throwError);
			}
		},
		enableReinitialize: true,
	});

	if (error) throwError(error);
	if (!data?.getUserById?.activeAgent?.id) return null;

	if (editClicked) {
		if (!data?.getUserById?.activeAgent?.id) return null;
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<Form.Input autoFocus loading={loading} label="Office">
					<OfficeDropdown
						fluid
						selection
						value={values.officeId}
						organizationId={data?.getUserById?.activeAgent?.organization?.id || ''}
						onChange={val => setFieldValue('officeId', val)}
					/>
				</Form.Input>
				<Button disabled={mutationLoading} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" onClick={() => setEditClicked(true)} compact>
			Change Office
		</Button>
	);
}
