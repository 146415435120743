import {useCan} from '@imperium/auth-client';
import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {matchPath} from 'react-router-dom';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection} from '~common/types';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {FiscalYearLocked} from './components/FiscalYearLocked';
import {HistoricDataEntryMode} from './components/HistoricDataEntryMode';
import {routes} from './routes';
import {useAccountingState} from './state';

const d = debug('tacs.web.accounting.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: routes.match.balanceSheet,
			selection: DateRangeSelection.SingleDate,
		}),
		dateRangeSelectionHook({
			routeMatch: path => matchPath(path, {path: '/account/:id/accounting/close-year'}),
			selection: DateRangeSelection.FullYear,
		}),
	],
	primaryMenu: [],
	statusbar: [
		{
			stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
			permissionSelectorHook: [
				data => {
					const [viewAdminPayrollAccount] = useCan([
						{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
					]);
					return {viewAdminPayrollAccount};
				},
			],
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				...isLoggedIn(),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
				'state.historicDataEntryMode': {$eq: true},
			},
			render: () => <HistoricDataEntryMode />,
			position: 'left',
		},
		{
			stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
			visible: {
				...isLoggedIn(),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			render: () => <FiscalYearLocked />,
			position: 'left',
		},
	],
	secondaryMenu: [
		{
			text: 'Accounting',
			stateSelectorHook: [useLayoutState, useAccountState],
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				...needsRoute('account'),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
			},
			to: data => (data.state.params?.accountInfoId ? routes.to.expenses({accountInfoId: data.state.params.accountInfoId}) : ''),
			dropdown: [
				{
					text: 'Opening Balances',
					icon: 'balance scale',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
						'state.historicDataEntryMode': {$eq: true},
					},
					to: data => (data.state.params?.accountInfoId ? routes.to.openingBalances({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Import Simply File',
					icon: 'cloud upload',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
						'state.historicDataEntryMode': {$eq: true},
					},
					to: data => (data.state.params?.accountInfoId ? routes.to.import({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Expenses',
					icon: 'book',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...needsPermission(Permission.ReadAllAccount),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.SelfEmployedAccount]},
					},
					to: data => (data.state.params?.accountInfoId ? routes.to.expenses({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Pay Statements',
					icon: 'file pdf',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...needsPermission(Permission.ReadAllAccount),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.SelfEmployedAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.payStatements({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Business Report',
					icon: 'balance scale',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {...isLoggedIn(), ...needsRoute('account'), 'state.type': {$in: [AccountTypeEnum.SelfEmployedAccount]}},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.businessReport({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'General Ledger',
					icon: 'columns',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
						'state.historicDataEntryMode': {$eq: false},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.generalLedger({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'General Journal',
					icon: 'clipboard list',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
						'state.historicDataEntryMode': {$eq: false},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.generalJournal({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},

				{
					text: 'Payables',
					icon: 'money bill alternate outline',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.payables({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Payments',
					icon: 'credit card outline',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.payments({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Receivables',
					icon: 'money bill alternate outline',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.receivables({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Receipts',
					icon: 'file alternate outline',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.receipts({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Income Statement',
					icon: 'chart line',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
						'state.historicDataEntryMode': {$eq: false},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.incomeStatement({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Balance Sheet',
					icon: 'balance scale',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
						'state.historicDataEntryMode': {$eq: false},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.balanceSheet({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				{
					text: 'Vendors',
					icon: 'handshake outline',
					stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
					visible: {
						...isLoggedIn(),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.vendors({accountInfoId: data.state.params.accountInfoId}) : '';
					},
				},
				// templates
				{
					text: 'Journal Entry Templates',
					icon: 'copy outline',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {
						...isLoggedIn(),
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.journalEntryTemplates({accountInfoId: data.state.params.accountInfoId}) : '';
					},
					moveToKey: 'settings',
				},
				{
					text: 'Payable Templates',
					icon: 'copy outline',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {
						...isLoggedIn(),
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.payableTemplates({accountInfoId: data.state.params.accountInfoId}) : '';
					},
					moveToKey: 'settings',
				},
				{
					text: 'Receivable Templates',
					icon: 'copy outline',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {
						...isLoggedIn(),
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						...needsRoute('account'),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
					},
					to: data => {
						return data.state.params?.accountInfoId ? routes.to.receivableTemplates({accountInfoId: data.state.params.accountInfoId}) : '';
					},
					moveToKey: 'settings',
				},
			],
		},
		{
			text: 'Chart of Accounts',
			icon: 'file alternate',
			stateSelectorHook: [useLayoutState, useAccountState, useAccountingState],
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
			},
			to: data => (data.state.params?.accountInfoId ? routes.to.chartOfAccounts({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
		},
	],
};
