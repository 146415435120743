import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'semantic-ui-react';
import {OrganizationDropdown} from '~common/components/OrganizationDropdown';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useEditAgentOrganizationMutation} from './editAgentOrganization';
import {useGetEditAgentOrganizationQuery} from './getEditAgentOrganization';

const d = debug('tacs.web.user.components.EditAgentOrganization');

export function EditAgentOrganization() {
	const throwError = useAsyncError();
	const {userId} = useParams<typeof routes.types.user>();
	const [editMutation, {loading: mutationLoading}] = useEditAgentOrganizationMutation();
	const {data, loading, error, refetch} = useGetEditAgentOrganizationQuery({variables: {userId: userId || ''}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {organizationId: data?.getUserById?.activeAgent?.organization?.id},
		onSubmit: formValues => {
			if (data?.getUserById?.activeAgent) {
				editMutation({
					variables: {
						data: {id: data?.getUserById?.activeAgent.id, organizationId: formValues.organizationId, version: data.getUserById?.activeAgent.version},
					},
				})
					.then(async () => {
						toast.success('Organization Saved');
						setEditClicked(false);
						await refetch();
					})
					.catch(throwError);
			}
		},
		enableReinitialize: true,
	});

	if (error) throwError(error);
	if (!data?.getUserById?.activeAgent?.id) return null;

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<Form.Input autoFocus loading={loading} label="Organization">
					<OrganizationDropdown fluid selection value={values.organizationId} onChange={val => setFieldValue('organizationId', val)} />
				</Form.Input>
				<Button disabled={mutationLoading} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" onClick={() => setEditClicked(true)} compact>
			Change Organization
		</Button>
	);
}
