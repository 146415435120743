import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getUserById.graphql';

const defaultOptions = {} as const;
export type GetUserByIdQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetUserByIdQuery = {
	getUserById?: {
		id: string;
		version: number;
		name: string;
		email: string;
		phone?: string | null;
		registrationDate: LocalDate;
		activeAgent?: {
			id: string;
			version: number;
			roles: Array<Types.AgentRole>;
			office: {id: string; name: string};
			organization: {id: string; name: string};
			supervisor?: {id: string; version: number; authorizedUser?: {id: string; name: string} | null} | null;
		} | null;
	} | null;
	getAccountInfoByUserId?: Array<{id: string; version: number; name: string}> | null;
};

export type GetUserByIdType = {
	id: string;
	version: number;
	name: string;
	email: string;
	phone?: string | null;
	registrationDate: LocalDate;
	activeAgent?: {
		id: string;
		version: number;
		roles: Array<Types.AgentRole>;
		office: {id: string; name: string};
		organization: {id: string; name: string};
		supervisor?: {id: string; version: number; authorizedUser?: {id: string; name: string} | null} | null;
	} | null;
};

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(Operations, options);
}
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(Operations, options);
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
