import {useAuthorization, useCan} from '@imperium/auth-client';
import {useLayoutState} from '@imperium/layout';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Dropdown, List, ListItem, Menu, MenuItem, Popup} from 'semantic-ui-react';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes as administrationRoutes} from '../../../administration/routes';
import {routes as supportRoutes} from '../../../support/routes';
import {routes as taskRoutes} from '../../../tasks/routes';
import {routes as userRoutes} from '../../routes';
import {setOrganizationId, useUserState} from '../../state';
import {useGetAgentsByAuthorizedUserQuery} from './getAgentsByAuthorizedUser';
import {useSetActiveAgentMutation} from './setActiveAgent';

export function UserMenuItem() {
	const authorization = useAuthorization();
	const {push} = useHistory();
	const dispatch = useDispatch();
	const throwError = useAsyncError();
	const {userId, userName, organizationName, organizationId} = useUserState();
	const {isMobile} = useLayoutState();
	const [open, setOpen] = useState(false);
	const [readAllAccount] = useCan(Permission.ReadAllAccount);
	const {data, error} = useGetAgentsByAuthorizedUserQuery({variables: {userId}});
	const [setActiveAgent] = useSetActiveAgentMutation();

	if (error) throwError(error);

	if (!userId) return null;

	function updateAgent(newOrganizationId: string) {
		const agent = data?.getAgentsByAuthorizedUser?.find(a => a.organization.id === newOrganizationId);
		if (agent && userId) {
			setActiveAgent({variables: {agentId: agent.id}})
				.then(() => {
					push(taskRoutes.to.dashboard());
					dispatch(setOrganizationId(agent.organization.id));
					dispatch(setOrganizationId(agent.organization.name));
					authorization.clearCache(userId).catch(throwError);
					window.location.reload();
				})
				.catch(throwError);
		}
	}

	return (
		<Popup
			on={['click']}
			inverted
			content={
				<Menu inverted vertical onClick={() => setOpen(false)}>
					{data?.getAgentsByAuthorizedUser && data.getAgentsByAuthorizedUser.length > 1 && (
						<MenuItem>
							Organization:{' '}
							<Dropdown
								onChange={(event, dropdown) => updateAgent(dropdown.value?.toString() || '')}
								value={organizationId || ''}
								options={data?.getAgentsByAuthorizedUser?.map(agent => {
									return {
										text: agent.organization?.name,
										value: agent.organization?.id,
										key: agent.organization?.id,
									};
								})}
							/>
						</MenuItem>
					)}
					<Link to={userRoutes.to.user({userId})}>
						<MenuItem content="My Profile" />
					</Link>
					{readAllAccount && (
						<>
							<Link to={administrationRoutes.to.timeCards({userId})}>
								<MenuItem content="Time Card" />
							</Link>
							<Link to={userRoutes.to.agentReminders({userId})}>
								<MenuItem content="Reminders" />
							</Link>
						</>
					)}
					<Link to={supportRoutes.to.support({userId})}>
						<MenuItem content="Support" />
					</Link>
					<Link to={userRoutes.to.logout()}>
						<MenuItem content="Logout" />
					</Link>
				</Menu>
			}
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			trigger={
				isMobile ? (
					<MenuItem icon="user" />
				) : (
					<MenuItem
						style={{paddingTop: 2, paddingBottom: 2}}
						content={
							<List>
								<ListItem>{userName}</ListItem>
								{organizationName && <ListItem style={{fontSize: '11px'}}>{organizationName}</ListItem>}
							</List>
						}
					/>
				)
			}
		/>
	);
}
